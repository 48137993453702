<template>
  <div class="badgeWrapper">
    <div class="version">
      <div v-if="statistics">
        <ciam-text> {{ statistics.version }}</ciam-text>
        <span v-if="canIUpgrade" class="tag is-warning is-light" title="New version">
          New version available :
          <u>
            <a class="ml-1" @click="navigateToUpgrade" style="cursor: pointer;"> Want to upgrade ?</a>
          </u>
        </span>
      </div>
      <div v-else>
        <ciam-text>...</ciam-text>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router';

export default {
  props: {
    statistics: {
      type: Object,
      required: true,
    },
    canIUpgrade: {
      type: Boolean,
      required: true
    },
    deployment: {
      type: Object,
      required: true
    }
  },
  methods: {
    navigateToUpgrade() {
      router.push({
        name: 'DeploymentConfigurations',
        params: { id: this.deployment.id },
        hash: '#upgrade-on-demand'
      });
    }
  }
}
</script>